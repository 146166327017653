@import "uikit-global-variables";

.car-category-slider {
  max-width: 100vw;
  background-color: $global-muted-background;
  border-radius: $border-rounded-border-radius;
  outline: none;
  margin-top: $global-medium-gutter;
  padding-bottom: $global-margin;

  @media (max-width: $breakpoint-xlarge) {
    margin: 0;
    border-radius: 0; } }

.car-category-slider-container {

  //@media (min-width: $breakpoint-large-max)
  //  //margin-top: $global-medium-margin
  //  padding: 0 0 $global-medium-gutter 0
  //
  //@media (max-width: $breakpoint-small)
  //  padding: $global-margin 0

  .car-category-slider-title {
    //font-weight: 700
    font-size: 2rem;
    line-height: 1;
    text-align: left;
    //text-transform: uppercase
    margin: 0;
    padding: 0;

    @media (max-width: $breakpoint-large) {
      font-size: 1.8rem; }
    //padding: $global-small-margin
    @media (max-width: $breakpoint-medium) {
      font-size: 1.4rem; }
    @media (max-width: $breakpoint-small) {
      font-size: 1.2rem; } } }

.car-category-slider-container {
  padding: $global-margin;
  @media (max-width: $breakpoint-small) {
    padding: $global-margin $global-small-margin; } }

.car-actions-items {
  margin: 0 $global-margin;
  overflow-x: hidden;
  @media (max-width: $breakpoint-small) {
    margin: 0;

    .shop-item--media {
      border-radius: 0; } }

  .shop-item-content {
    @media (max-width: $breakpoint-small) {
      margin: $global-small-margin; } }

  .shop-item {
    background-color: transparent; } }

