//
// BASE
//

@import "uikit-global-variables";

html {
  min-width: 320px; }

// PT+Sans:400,400i,700
html,
body {
  font-weight: 400;
  font-family: 'PT Sans', sans-serif;
  min-height: 100vh;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available; } }

body {
  //background: #FFFFFF url(../images/spinner.gif) 50% 50% no-repeat
  background-size: 32px 32px; }

.car-body {
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  background-color: $global-background;
  display: grid;
  grid-template-rows: 1fr auto;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available; } }

.car-container {
  padding: 0;
  margin: 0;
  width: 100vw;
  max-width: 100vw;
  background-color: $global-background;
  transition: filter .3s ease-in-out, transform .3s ease-in-out; }

.car-content {
  margin: 0; }

.car-content-info {
  margin: $global-small-gutter 0;
  padding: 0 $global-small-margin; }

// car-badge
.car-badge-container {
  position: relative; }

.car-badge {
  position: absolute;
  bottom: 50%;
  right: 10%;
  z-index: 3;

  &.car-cart-badge {
    background-color: $global-success-background;
    animation: bounce 1.5s linear infinite; } }

//animations
@-webkit-keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(0); }

  50%, 70% {
    transform: translateY(-5px); } }

// button-call
.button-call {
  & > * {
    animation: call 2s linear infinite;

    path {
      fill: #ffffff; } } }

@-webkit-keyframes call {
  0%, 50%, 60%, 70%, 80%, 100% {
    transform: rotate(135deg) scale(1); }

  55%, 75% {
    transform: rotate(45deg) scale(1); } }

// borders
.car-border-top {
  border-top: $global-border-width solid $global-border; }

// border radius
.car-border-radius-top {
  border-radius: $border-rounded-border-radius $border-rounded-border-radius 0 0; }

.car-border-radius-bottom {
  border-radius: 0 0 $border-rounded-border-radius $border-rounded-border-radius; }

// links actions
.uk-no-link {
  color: $global-color;
  border-bottom: $global-border-width dotted $global-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $global-link-color;
    border-color: transparent;
    text-decoration: none; } }

// car-overflow-y
.car-overflow-y {
  overflow-x: hidden;
  overflow-y: auto; }

// car-menu-icon
.uk-icon-image {
  &.car-menu-icon {
    width: 18px;
    height: 18px; } }

.uk-background-contain {
  background-size: contain !important; }

/**
  * MAP Contacts
  */

#map {
  height: 300px;
  background-color: $global-muted-background;
  // border-radius: $border-rounded-border-radius * 2
  overflow: hidden;
  margin: 0 -10px; }
