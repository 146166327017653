@import "uikit-variables";

// transition
@mixin transition($parameter) {
  transition: $parameter .3s ease; }

@mixin transitionTime($duration) {
  transition: $duration ease; }

@mixin transitionTimeParameter($duration, $parameter) {
  transition: $parameter $duration ease; }

@mixin transitionAll() {
  transition: .3s ease; }

// shadow
@mixin box-shadow($size) {
  box-shadow: 0 2px $size rgba($global-color, .1); }

// $border-rounded
@mixin border-rounded() {
  border-radius: $border-rounded-border-radius; }

// text shadow
@mixin text-shadow() {
  text-shadow: 0 1px 2px rgba(0, 0, 0, .5); }
