// @import "uikit-global-variables"
@import "mixin";

//
// Header
//
.car-header {
  flex: none; }

.car-header-top {
  position: relative;
  padding-top: 30px;

  .uk-navbar-item,
  .uk-navbar-nav > li > a,
  .uk-navbar-toggle {
    min-height: 55px;
    height: 55px; }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50px;
    right: 0;
    height: 12px;
    background: 0 100% url(../img/logo/logo_border.svg) repeat-x;
    background-size: 20px 12px;
    @media (max-width: $breakpoint-large) {
      background-size: 20px * .7 13px * .7; } } }
//opacity: .7

.car-header-logo {

  height: 56px;
  position: relative;
  min-width: 80px;
  display: block;
  @media (max-width: $breakpoint-xxlarge) {
    margin: 0 15px; }
  @media (max-width: $breakpoint-large) {
    min-width: 80px;
    height: 56px * .7; } }

.car-header-logo--label {
  width: auto;
  height: 56px;
  // margin-left: 90px
  @media (max-width: $breakpoint-large) {
    // width: 332px * .7
    height: 56px * .7; } }
// margin-left: 90px * .7

.car-header-logo--image {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  @media (max-width: $breakpoint-large) {
    width: 80px * .7;
    height: 80px * .7; } }


.car-header-nav {
  background-color: $global-background; }
// slider
.car-header-content {
  position: relative;
  border-radius: $border-rounded-border-radius;
  //height: 375px
  overflow: hidden;
  @media (max-width: $breakpoint-xxlarge) {
    border-radius: 0; } }

.car-header-content--overlay {
  padding: $global-medium-margin;
  box-sizing: border-box;
  //background-color: rgba($global-primary-background, .2)
  //background-image: linear-gradient(to top right, rgba($global-danger-background, .5), rgba($global-primary-background, .5))
  @media (max-width: $breakpoint-small) {
    padding: $global-margin; } }

.car-header--title {
  border-top: $global-border-width solid $global-border;
  font-weight: 700;
  //color: $global-color
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: $global-margin 0;
  @media (max-width: $breakpoint-large) {
    font-size: 1.8rem; }
  @media (max-width: $breakpoint-medium) {
    font-size: 1.4rem; }
  @media (max-width: $breakpoint-small) {
    font-size: 1.2rem; } }

.car-header-content--title {
  font-weight: 700;
  color: $global-inverse-color;
  font-size: 2rem;
  line-height: .9;
  text-transform: uppercase;
  margin: 0;
  text-shadow: 0 0 5px rgba(0, 0, 0, .5);
  position: relative;
  @include text-shadow;
  @media (max-width: $breakpoint-large) {
    font-size: 1.8rem; }
  @media (max-width: $breakpoint-medium) {
    font-size: 1.6rem; }
  @media (max-width: $breakpoint-small) {
    font-size: 1.2rem; } }

.car-header-nav-logo--image {
  width: 50px;
  height: 50px;
  @media (max-width: $breakpoint-small) {
    width: 30px;
    height: 30px; } }

.text-shadow {
  text-shadow: 0 0 5px rgba(0, 0, 0, .5); }

.header-overlay {
  background-image: linear-gradient(to top left, rgba($global-color, .5), rgba($global-color, .2)); }

// Кнопка назад в мобильном приложении
body {
  &.application {
    .nav-menu-toggle {
      display: none; }

    .car-body {
      padding-bottom: 60px; } }

  &.site {
    .nav-back-link {
      display: none; } } }

.nav-back-link {
  svg {
    & > * {
      stroke-width: 1.7;
      stroke-linejoin: round;
      stroke-linecap: round; } } }
