@import "uikit-global-variables";
@import "mixin";

.shop-item {
  @include transitionTimeParameter(transform, .6s);
  box-sizing: border-box;
  position: relative;
  border-radius: $border-rounded-border-radius;
  background-color: $global-background; }

.shop-item-content {
  margin-top: $global-small-margin;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  //@media (max-width: $breakpoint-small)
  //  padding: $global-small-margin
  //  margin: 0
  //  height: 100%

  .shop-item--description {
    margin-top: $global-small-margin / 2;
    font-size: .75rem;
    line-height: 1.2;
    @media (max-width: $breakpoint-small) {
      font-size: .65rem; } } }

.shop-item--media {
  position: relative;
  background: 50% 50% no-repeat;
  background-size: cover;
  background-color: $global-muted-background;
  border-radius: $border-rounded-border-radius;
  overflow: hidden;
  @include transition(transform); }

.shop-item-media--overlay {
  padding: $global-small-gutter;
  @media (max-width: $breakpoint-small) {
    display: none; }

  .shop-item--description {
    color: $global-color; } }

.shop-item-buy {
  flex: 1;
  margin-top: $global-small-margin / 2; }

.shop-item--title {
  color: $global-success-background;
  margin: 0;
  font-weight: 700;
  line-height: 1.2;
  //flex: none
  hyphens: auto;

  &:hover {
    color: darken($global-success-background, 5%); }

  @media (max-width: $breakpoint-small) {
    font-size: .875rem;
    font-weight: normal; } }

.shop-item--description {
  margin: 0;
  color: $global-muted-color;
  flex: none; }

.shop-item-price--summ {
  //font-weight: 700
  font-size: 1.3rem;
  line-height: 1;
  color: $global-success-background;
  @media (max-width: $breakpoint-small) {
    font-size: 1.1rem; } }

.shop-item-price--currency {
  //font-weight: 700
  font-size: 1.3rem;
  line-height: 1;
  color: $global-success-background;
  @media (max-width: $breakpoint-small) {
    font-size: 1.1rem; } }

.shop-item--weight {
  line-height: 1;
  color: $global-muted-color;
  font-size: .875rem; }

.shop-item--add-to-cart {
  @include transitionTime(.6s);
  //background-color: $global-success-background
  transform-origin: center center;
  //color: $global-inverse-color
  //height: 30px
  min-width: 30px;
  @media (max-width: $breakpoint-medium) {
    padding: 0; }

  &:hover {
    color: $global-inverse-color;
    background-color: darken($global-success-background, 5%); }

  @media (max-width: $breakpoint-small) {
    height: 30px;
    width: 30px;
    line-height: 30px;
    .out-cart {
      width: 16px;
      height: 16px; } }

  &:hover {} }
//animation: paused
//transform: scale(1.1) translateY(-2px)

.car-mini-cart--item {
  .shop-item--add-to-cart {
    &:hover {
      transform: none; } } }

.shop-item {
  &:hover {
    //@media (max-width: $breakpoint-small)
    //  transform: translateY(-3px)

    .shop-item--media {
      transform: translateY(-3px);
      color: $global-muted-color;
      box-shadow: $global-medium-box-shadow; } } }
//@media (max-width: $breakpoint-small)
//  transform: none

.shop-item-badges {
  padding: $global-small-margin; }
//@media (max-width: $breakpoint-small)
//  padding: 0

.shop-item-badge {
  line-height: 30px;
  box-sizing: border-box;
  width: 34px;
  height: 34px;
  border-radius: 500px;
  border: 2px solid $global-inverse-color;
  position: relative;
  z-index: 1;
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  color: $global-inverse-color;
  text-align: center;
  background: $global-danger-background 50% 50% no-repeat;
  background-size: 14px 14px;
  @include box-shadow(3px);
  @include transition(transform);

  &:hover {
    transform: translateY(-2px); }

  &.primary {
    background-color: $global-warning-background; }

  &.success {
    background-color: $global-success-background; }

  // action
  &.action {
    background-image: url(../img/icons/action.svg); }

  // choice
  &.choice {
    background-image: url(../img/icons/choice.svg); }

  // action
  &.new {
    background-image: url(../img/icons/new.svg); }

  &:not(:first-child) {
    margin-left: -10px; }

  @media (max-width: $breakpoint-small) {
    line-height: 26px;
    width: 30px;
    height: 30px;

    &:not(:first-child) {
      margin-left: -15px; } } }

.uk-tooltip {
  &.primary {
    background-color: $global-warning-background; }

  &.success {
    background-color: $global-success-background; } }

/* animations */
@-webkit-keyframes bounce {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: translateY(0); }

  50%, 70% {
    transform: translateY(-5px); } }

// Товар в корзине
.shop-item {

  &:not(.in-cart) {

    .shop-item--add-to-cart {
      .in-cart {
        display: none; }

      .out-cart {
        display: inline-block; } } }

  &.in-cart {
    .shop-item-media--overlay {
      display: none; }

    .shop-item--add-to-cart {
      background-color: $global-danger-background;

      &:hover {

        color: $global-inverse-color;
        background-color: darken($global-danger-background, 5%); }

      .out-cart {
        display: none; }

      .in-cart {
        display: inline-block; } } } }

// shop-item-fav
.shop-item-fav {
  position: absolute;
  top: $global-small-margin + 5;
  right: $global-small-margin + 5;
  width: 22px;
  height: 22px;
  line-height: 25px;
  // box-shadow: 2px 0 2px rgba(0, 0, 0, .3)

  &:focus,
  &:active {
    outline: none; }

  &:before {
    animation: none;
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba($global-background, .8);
    border-radius: 500px;
    transform: translate(-50%, -50%); }

  &:not(.in-fav) {
    &:before {
      background-color: rgba($global-background, .8); } }

  &.in-fav {
    &:before {
      background-color: rgba($global-color, .6); } }

  &:hover {
    & > * {
      animation: heart 1.5s linear infinite; } }

  svg {
    width: 22px;
    height: 22px;

    .fill {
      fill: $global-inverse-color; }

    .border {
      fill: $global-danger-background; } }

  &.in-fav {
    svg {

      .fill {
        fill: $global-danger-background; }

      .border {
        fill: $global-inverse-color; } } } }

//animations
@-webkit-keyframes heart {
  0%, 20%, 40%, 60%, 80%, 100% {
    transform: scale(1); }

  50%, 70%, 90% {
    transform: scale(1.1); } }

.page-item-params {
  font-size: .9rem;
  display: flex;
  line-height: 1.2;
  & > * {
    padding: 3px 0; }

  &:not(:last-child) {
    border-bottom: 1px solid $global-border; } }

.page-item-params__title {
  flex: none;
  // max-width: 40%
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.page-item-params__value {
  text-align: right;
  min-width: 0;
  flex: 1; }

