@import "uikit-global-variables";
@import "mixin";

.car-cart {}

// CART ADD Stepper
.shop-item-counter {}

.car-body {
  @include transition(transform); }
//transform: scale(0)
//filter: blur(0)

.uk-modal-page {
  .car-body {
    transform: scale(.95);
    filter: blur(5px);
    overflow: hidden; }

  .uk-modal {
    filter: blur(0); } }

.shop-item-counter-dialog {
  overflow: hidden;
  box-sizing: border-box;
  background-color: $global-inverse-color;
  border-radius: $border-rounded-border-radius*2 $border-rounded-border-radius*2 0 0;
  box-shadow: $global-large-box-shadow;
  @include transition(transform);
  z-index: $global-z-index;
  max-width: 450px;
  margin: 0 auto;
  transform: translateY(100%);

  &.page {
    position: static;
    transform: none;
    max-width: none;
    box-shadow: none;
    border-radius: 0; }

  .cart-stepper--increase,
  .cart-stepper--decrease {
    margin: 0;
    padding: 0;
    height: $global-control-height;
    width: $global-control-height;
    box-sizing: border-box;
    text-align: center; }

  .cart-stepper--increase {
    border-radius: 0 $border-rounded-border-radius $border-rounded-border-radius 0; }

  .cart-stepper--decrease {
    border-radius: $border-rounded-border-radius 0 0 $border-rounded-border-radius; }

  .cart-stepper--input {
    border-color: $global-success-background;
    border-width: $global-border-width 0;

    &:focus {
      border-color: $global-success-background;
      background-color: rgba($global-success-background, .1); } }

  & > .uk-card-body {
    background-color: $global-inverse-color;
    padding: $global-margin $global-gutter;

    @media (max-width: $breakpoint-small) {
      padding: $global-margin; } }

  .shop-item-counter-action {
    background-color: $global-muted-background;
    border-top: $global-border-width solid $global-border; } }

.shop-item-counter {
  &.uk-open {
    .shop-item-counter-dialog {
      transform: translateY(0); } }

  &.close {
    .shop-item-counter-dialog {
      transform: translateY(100%); } } }

.car-cart-promo-code {
  cursor: pointer;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: row;
  border-radius: 500px;
  align-items: center;
  background-color: $global-success-background;
  color: $global-inverse-color;
  font-size: .75rem;
  line-height: 24px;
  padding: 0 5px 0 10px;
  white-space: nowrap;
  vertical-align: middle;

  &:hover {
    background-color: darken($global-success-background, 5%); } }

.car-cart-total {
  &.uk-sticky-fixed {
    z-index: 2; } }
