@import "uikit-global-variables";
@import "mixin";


.offcanvas-cart {
  &.uk-offcanvas-overlay {
    &.uk-open {
      &::before {
        background-color: rgba($global-success-background, .6); } } } }

.offcanvas-cart-content {
  background-color: $global-background;
  padding: 0;

  .uk-offcanvas-close {
    top: 15px;
    right: 15px; } }

.car-cart-mini-top,
.car-cart-mini-bottom {
  position: absolute;
  box-sizing: border-box;
  padding: $global-margin / 2 $global-margin;
  right: 0;
  left: 0;

  & > * {
    margin: 0; } }

.car-cart-mini-middle {
  padding: $global-margin / 2;
  position: absolute;
  background-color: $global-muted-background;
  top: 50px;
  bottom: 80px;
  //overflow: auto
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  right: 0;
  left: 0; }

.car-cart-mini-middle--scroll {
  min-height: min-content; }

.car-cart-mini-top {
  border-bottom: 1px solid $global-border;
  height: 50px;
  top: 0; }

.car-cart-mini-bottom {
  border-top: 1px solid $global-border;
  height: 80px;
  bottom: 0; }

.car-mini-cart--item {
  position: relative;
  padding: $global-margin / 2;
  border-radius: $border-rounded-border-radius;
  box-shadow: $global-small-box-shadow;
  background-color: $global-background;
  @include transitionAll;

  &:not(:last-child) {
    margin-bottom: $global-margin / 2; }

  &:hover {
    transform: scale(.99);
    box-shadow: $global-xsmall-box-shadow; }

  & .cart-stepper--increase, & .cart-stepper--decrease {
    min-width: 10px; } }

.cart-stepper--input {
  width: 70px;
  margin: 0 5px;
  text-align: center; }

.car-mini-cart-item__title {
  margin-bottom: $global-small-margin; }

.car-mini-cart-item__image {
  position: relative; }

.car-cart-item__balls {
  background-color: rgba($global-success-background, .9);
  color: $global-inverse-color;
  line-height: 1;
  text-align: center;
  padding: 3px;
  font-size: .875rem;
  border-radius: $border-rounded-border-radius; }

.application {
  .offcanvas-cart {
    bottom: 60px;
    box-shadow: none;
    overflow: hidden;
    border-bottom: 1px solid $global-border; } }
