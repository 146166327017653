@import "uikit-global-variables";
@import "mixin";

.car-category-item {
  background-color: $global-muted-background;
  overflow: hidden;
  position: relative;

  canvas {
    @include transitionTimeParameter(transform, 8s);
    border-radius: $border-rounded-border-radius;
    transform-origin: center center;

    @media (max-width: $breakpoint-xlarge) {
      border-radius: 0; } }

  //@media (max-width: $breakpoint-medium)
  //  canvas
  //    width: 100%
  //    height: 220px
  //
  //@media (max-width: $breakpoint-small)
  //  canvas
  //    width: 100%
  //    height: 140px

  &:hover {
    canvas {
      transform: scale(1.2); } } }
//
//.car-category-last
//  .car-category-item
//    canvas
//      height: 302px
//      width: 100%

//  //@media (max-width: $breakpoint-large)
//  //  canvas
//  //    width: 100%
//  //    height: 220px

.uk-grid-collapse {
  .car-category-item {
    margin-bottom: 1px; }

  & > .uk-first-column {
    .car-category-item {
      margin-right: 1px; } } }

.car-category--content {
  padding: $global-medium-margin $global-margin $global-margin $global-margin;
  box-sizing: border-box;
  //background-image: linear-gradient(to top right, rgba($global-danger-background, .2), rgba($global-primary-background, .2))
  @media (max-width: $breakpoint-xlarge) {
    padding: $global-margin $global-small-gutter $global-xsmall-margin $global-small-gutter; }

  @media (max-width: $breakpoint-large) {
    padding: $global-margin $global-small-margin $global-xsmall-margin $global-small-margin; }

  @media (max-width: $breakpoint-medium) {
    padding: $global-small-margin $global-small-margin 0 $global-small-margin; }

  @media (max-width: $breakpoint-small) {
    padding: $global-small-margin $global-small-margin 0 $global-small-margin; } }

.car-category-content--title {
  //font-weight: 700
  font-size: 1.6rem;
  text-align: center;
  line-height: 1;
  text-transform: lowercase;
  margin: 0;
  @media (max-width: $breakpoint-large) {
    font-size: 1.4rem; }

  @media (max-width: $breakpoint-medium) {
    font-size: 1.2rem; }

  @media (max-width: $breakpoint-small) {
    font-size: 1rem; } }

.car-category-title {
  font-weight: 400;
  font-size: 2rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: $global-margin;
  @media (min-width: $breakpoint-large-max) {
    padding-top: $global-medium-margin;
    padding-bottom: $global-medium-margin; }
  @media (max-width: $breakpoint-large) {
    font-size: 1.8rem; }
  @media (max-width: $breakpoint-medium) {
    font-size: 1.4rem; }
  @media (max-width: $breakpoint-small) {
    padding: $global-margin $global-small-margin;
    font-size: 1.2rem; } }

$group-filter-height: 26px;

.car-group-filter-container {
  margin-bottom: $global-medium-margin;
  overflow-x: auto;
  overflow-y: hidden;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0; } }

.car-group-filter {
  width: auto;
  display: flex;
  flex-wrap: nowrap; }

.car-group-filter-item {
  line-height: $group-filter-height;
  border-radius: 500px;
  height: $group-filter-height;
  background-color: $global-muted-background;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 5px; }

  &:hover {
    text-decoration: none;
    // color: $global-inverse-color
    background-color: $global-warning-background; }

  &.active {
    color: $global-inverse-color;
    background-color: $global-success-background;

    .car-group-filter-item__count {
      color: $global-color;
      background-color: $global-warning-background; } } }



.car-group-filter-item__label {
  flex: 1;
  white-space: nowrap;
  min-width: 0;
  padding: 0 $group-filter-height / 3; }

.car-group-filter-item__count {
  border-radius: 500px;
  flex: none;
  font-size: .8rem;
  text-align: center;
  min-width: $group-filter-height;
  height: $group-filter-height;
  color: $global-inverse-color;
  background-color: $global-success-background; }
