@import "uikit-global-variables";
@import "mixin";

.mm-wrapper_background {
  .mm-page {
    background-color: $global-background !important;
    box-shadow: $global-medium-box-shadow;

    .car-container {
      overflow: hidden; } } }

.mm-wrapper__blocker {
  &.mm-slideout {
    background-color: rgba($global-background, .8); } }

.mm-navbar__title {
  font-weight: 700;
  color: $global-success-background !important; }

// side menu

.side-menu-container {
  //min-height: 400px
  z-index: 10; }

.mm-listitem__btn {
  padding-right: 30px; }

.mm-btn_next:after {
  right: 15px; }

.mm-counter {
  min-width: 25px; }

.side-menu {}

.mm-listitem {
  text-transform: uppercase;
  font-size: .8rem;
  & > .mm-listitem__text,
  &:hover {
    color: $global-success-background; }

  & > .mm-btn_next {
    &:hover {
      & > .mm-counter {
        color: $global-success-background; }

      &:after,
      &:before {
        border-color: $global-success-background; } } } }

// footer fix
.mm-menu {
  &.mm-menu_offcanvas {
    bottom: 60px; } }
