@import "uikit-variables";
@import "uikit-mixins";
@import "mixin";

$progress-height: 20px;
$progress-bar-height: $progress-height / 2;
$progress-step-height: $progress-height;
$progress-step-outer-height: $progress-height * 1.3;
$progress-background: $global-success-background;
$progress-tooltip-width: 200px;
$progress-tooltip-small-width: 160px;
$progress-tooltip-move: -($progress-height/2);
$progress-tooltip-drop-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);

.order-step-progress {
  margin: 0 auto;
  max-width: $breakpoint-small;
  padding: ($global-medium-margin + $global-small-margin) $global-small-margin $global-margin $global-small-margin;
  @media (max-width: $breakpoint-small) {
    padding: $global-margin $global-small-margin; } }


.se-pay-progress-outer {
  position: relative;

  &:hover {
    .se-pay-progress--item {
      &:not(.complete).current {
        .se-pay-progress-item--inner {
          animation-play-state: running; } } } } }

.se-pay-progress--bar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: lighten($progress-background, 15%);
  height: $progress-bar-height;
  border-radius: $progress-bar-height;
  content: "";
  display: block;
  will-change: transform;
  transition: width 1s ease-in-out;
  @media (max-width: $breakpoint-small) {
    height: $progress-bar-height; } }

.se-pay-progress {
  height: $progress-bar-height;
  border-radius: $progress-bar-height;
  //background-color: $progress-background
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  transform: translateZ(0);
  will-change: transform;
  box-shadow: inset 0 0 5px 0 rgba($global-color, .3);
  @media (max-width: $breakpoint-small) {
    height: $progress-bar-height; } }

.se-pay-progress--item {
  z-index: 1;
  position: relative;
  border-radius: $progress-height;
  width: $progress-step-outer-height;
  height: $progress-step-outer-height;
  background-color: $global-background;
  @media (max-width: $breakpoint-small) {
    width: $progress-step-outer-height;
    height: $progress-step-outer-height; } }

.se-pay-progress-item--inner {
  //cursor: pointer
  position: absolute;
  top: 50%;
  left: 50%;
  width: $progress-step-height;
  height: $progress-step-height;
  content: "";
  display: block;
  border-radius: $progress-step-height;
  backface-visibility: hidden;
  transform: translate(-50%, -50%);
  background-color: $global-danger-background;
  box-shadow: $global-small-box-shadow;
  @include transitionTime(.4s);
  @media (max-width: $breakpoint-small) {
    width: $progress-step-outer-height;
    height: $progress-step-outer-height; }

  &.complete {
    background-color: $global-success-background; }

  &:not(.complete).current {
    background-color: $global-color;
    width: $progress-step-height*1.2;
    height: $progress-step-height*1.2;
    @media (max-width: $breakpoint-small) {
      width: $progress-step-outer-height;
      height: $progress-step-outer-height; } }

  &:hover {
    background-color: darken($global-color, 5%);
    box-shadow: $global-medium-box-shadow; } }

.se-pay-progress-item--tooltip {
  padding: $global-small-margin;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  width: $progress-tooltip-width;
  border-radius: $border-rounded-border-radius;
  background-color: $global-danger-background;
  color: $global-inverse-color;
  line-height: 1.2;
  font-size: 1rem;
  bottom: ($progress-height*2);
  left: -($progress-tooltip-width/2)+($progress-step-outer-height/2);
  box-shadow: $progress-tooltip-drop-shadow;
  z-index: 4;
  will-change: transform;
  @include transitionTime(.4s);
  opacity: 0;
  transform: translateY($progress-tooltip-move);
  @media (max-width: $breakpoint-small) {
    display: none; }
  //top: 27px
  //padding: ($global-small-margin - 2)
  //font-size: 1rem
  //transform: translateY(-$progress-height)
  //width: $progress-tooltip-small-width
  //left: -($progress-tooltip-small-width/2)+($progress-step-outer-height/2)

  &:before {
    content: "";
    display: block;
    top: 100%;
    position: absolute;
    width: 0;
    //z-index: 0
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: $global-danger-background transparent transparent transparent;
    filter: drop-shadow($progress-tooltip-drop-shadow);
    left: 50%;
    will-change: transform;
    transform: translateX(-50%); } }

.se-pay-progress--item {

  &.complete {
    .se-pay-progress-item--inner {
      background-color: $global-success-background; }

    .se-pay-progress-item--tooltip {
      background-color: $global-success-background;
      color: $global-inverse-color;

      &:before {
        border-color: $global-success-background transparent transparent transparent; } } }

  &:not(.complete).current {
    width: $progress-step-outer-height;
    height: $progress-step-outer-height;
    @media (max-width: $breakpoint-small) {
      width: $progress-step-outer-height;
      height: $progress-step-outer-height; }

    .se-pay-progress-item--inner {
      will-change: transform;
      background-color: $global-color;
      width: $progress-step-height*1.2;
      height: $progress-step-height*1.2;
      box-shadow: 0 0 0 rgba($global-color, 0.4);
      animation: pulse 2s infinite;
      //animation-play-state: paused
      @media (max-width: $breakpoint-small) {
        width: $progress-step-outer-height;
        height: $progress-step-outer-height; } }

    .se-pay-progress-item--tooltip {
      opacity: 1;
      transform: translateY(0);
      background-color: $global-color;
      color: $global-inverse-color;

      &:before {
        border-color: $global-color transparent transparent transparent; } } }

  &:hover {
    .se-pay-progress-item--tooltip {
      opacity: 1;
      will-change: transform;
      transform: translateY(0); } }

  &:last-child {
    .se-pay-progress-item--tooltip {
      left: auto;
      right: -($progress-step-outer-height/2);

      &:before {
        left: auto;
        right: 15px;
        will-change: transform;
        transform: translateX(0); } } }

  &:first-child {
    .se-pay-progress-item--tooltip {
      will-change: transform;
      right: auto;
      left: -($progress-step-outer-height/2);

      &:before {
        left: 15px;
        right: auto;
        will-change: transform;
        transform: translateX(0); } } } }

.se-pay-progress-outer {
  &:hover {
    .se-pay-progress--item {
      &:not(.complete).current {
        .se-pay-progress-item--tooltip {
          opacity: 0;
          will-change: transform;
          transform: translateY($progress-tooltip-move); }

        &:hover {
          .se-pay-progress-item--tooltip {
            will-change: transform;
            opacity: 1;
            transform: translateY(-10px); } } } } } }

/* animations */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba($global-color, 0.4); }
  70% {
    box-shadow: 0 0 0 15px rgba($global-color, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba($global-color, 0); } }
