@import "uikit-global-variables";
@import "mixin";

body {
  &.application {

    .car-header-top,
    .car-footer {
      display: none !important; } }

  &.site {

    .car-container {
      margin: 0 auto;
      background-color: #fff;
      max-width: 1200px; } } }
