/**
 * Global var
 */
$deprecated: false !default;
// gutters
$global-small-gutter: 15px !default;
$global-gutter: 30px !default;
$global-medium-gutter: 40px !default;
$global-large-gutter: 70px !default;

// margins
$global-xsmall-margin: 5px !default;
$global-small-margin: 10px !default;
$global-margin: 20px !default;
$global-medium-margin: 40px !default;
$global-large-margin: 70px !default;
$global-xlarge-margin: 140px !default;

// fonts
$global-font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$global-title-font-family: 'PT Sans', sans-serif !default;
$global-title-font-wight: 700 !default;
$global-font-size: 16px !default;
$global-small-font-size: 0.875rem !default;
$global-medium-font-size: 1.25rem !default;
$global-large-font-size: 1.5rem !default;
$global-xlarge-font-size: 2rem !default;
$global-xxlarge-font-size: 2.625rem !default;
$global-line-height: 1.5 !default;

// colors
$global-background: #ffffff !default;
$global-emphasis-color: #549F1F !default;
$global-color: rgb(91,91,91) !default;
$global-muted-color: #9d9898 !default;
$global-muted-background: #f8f8f8 !default;
$global-secondary-background: #3d403a !default;

$global-link-color: rgb(91,91,91) !default;
$global-link-hover-color: darken($global-link-color, 10%) !default;

$global-primary-background: #16B9FF !default;
$global-success-background: rgb(176,203,31) !default;
$global-warning-background: #FFCE21 !default;
$global-danger-background: #FF4C28 !default;
$global-inverse-color: #ffffff !default;

$inverse-global-color-mode: light !default;
$inverse-global-muted-color: rgba($global-inverse-color, 0.5) !default;
$inverse-global-emphasis-color: $global-inverse-color !default;
$inverse-global-color: rgba($global-inverse-color, 0.7) !default;
$inverse-global-primary-background: $global-inverse-color !default;
$inverse-global-inverse-color: $global-color !default;

// border
$global-border-width: 1px !default;
$global-border: #e5e5e5 !default;
$border-rounded-border-radius: 5px !default;

// control
$global-control-height: 40px !default;
$global-control-small-height: 30px !default;
$global-control-large-height: 55px !default;

// box-shadow
$global-xsmall-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08) !default;
$global-small-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08) !default;
$global-medium-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08) !default;
$global-large-box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16) !default;
$global-xlarge-box-shadow: 0 28px 50px rgba(0, 0, 0, 0.16) !default;

// other
$global-z-index: 1000 !default;

// breakpoint
$breakpoint-small: 480px !default;
$breakpoint-medium: 768px !default;
$breakpoint-large: 960px !default;
$breakpoint-xlarge: 1200px !default;
$breakpoint-xxlarge: 1600px !default;

$breakpoint-xsmall-max: ($breakpoint-small - 1) !default;
$breakpoint-small-max: ($breakpoint-medium - 1) !default;
$breakpoint-medium-max: ($breakpoint-large - 1) !default;
$breakpoint-large-max: ($breakpoint-xlarge - 1) !default;
$breakpoint-xlarge-max: ($breakpoint-xxlarge - 1) !default;
