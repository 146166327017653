@import "uikit-variables";
@import "uikit-mixins";
@import "mixin";

// time range
.car-button-radio {
  position: relative;

  & > label {
    display: block;
    cursor: pointer; }

  .uk-button {
    &.car-button-radio--toggle {
      color: $global-muted-color;
      border: $global-border-width solid $global-border;
      margin: 2px;
      padding: 0 $global-small-margin/1.7;
      font-size: .75rem;
      line-height: $global-control-small-height;
      height: $global-control-small-height;
      border-radius: $border-rounded-border-radius; } }

  input {
    &:disabled {
      & + .car-button-radio--toggle {
        background-color: $global-muted-background; } }

    &:not(:disabled) {
      & + .car-button-radio--toggle {
        &:hover {
          color: $global-color;
          border-color: $global-color; } }

      &:checked {
        & + .car-button-radio--toggle {
          background-color: $global-success-background;
          border-color: $global-success-background;
          color: $global-inverse-color; } } } } }

// payment-method
.car-payment-method-toggle {
  color: $global-muted-color;
  width: 80px;
  padding: $global-small-margin;
  border: $global-border-width solid $global-border;
  border-radius: $border-rounded-border-radius; }

.car-payment-method {
  display: block;
  cursor: pointer;
  text-align: center;

  &:hover {
    .car-payment-method-toggle {
      color: $global-color;
      border-color: $global-color; } } }

.car-payment-method--input {
  &:checked {
    & + .car-payment-method-toggle {
      background-color: $global-success-background;
      border-color: $global-success-background;
      color: $global-inverse-color; } } }

.car-payment-method--title {
  line-height: 1.2;
  font-size: .875rem;
  margin-top: $global-small-margin; }

.car-button-select {
  padding: 0 $global-small-margin;
  border-color: $global-border;
  color: $global-muted-color; }
