@import "uikit-global-variables";
@import "variables_mobile";

.car-footer {
  height: $footer-height;
  background-color: $global-secondary-background;
  flex: none; }

body {
  &.application {
    .app-hidden {
      display: none; }

    .car-body {
      padding-bottom: 60px; } }

  &.site {
    .car-footer-mobile {
      display: none; } } }

.car-footer-mobile {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  height: 60px;
  z-index: 1030;
  background-color: $global-background;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  & > * {
    width: 20%; }

  &__label {
    font-size: .7rem;
    line-height: 1;
    margin-top: 6px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

  &__link {
    cursor: pointer;
    height: 100%;
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: $global-muted-color;
    text-decoration: none;
    padding: 0 $global-margin;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    @media (max-width: $breakpoint-small) {
      padding: 0 $global-xsmall-margin; }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border: none;
      outline: none;
      text-decoration: none;
      color: $global-emphasis-color; }

    .car-cart-badge {
      top: 5%;
      right: 15%;
      animation: none; } } }
