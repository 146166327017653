@import "uikit-global-variables";
@import "mixin";
.uk-modal {
  bottom: 60px;

  &.uk-modal-full {

    .uk-height-viewport {
      max-height: calc(100vh - 60px) !important;
      min-height: calc(100vh - 60px) !important; } } }

.search-navbar {
  text-transform: none;
  max-height: calc(100vh - 80px);
  overflow: hidden;
  background-color: lighten($global-muted-background, 2%);
  border-top: $global-border-width solid $global-border;

  @media (max-width: $breakpoint-medium) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    padding: $global-small-margin; }

  .shop-item {
    box-shadow: $global-small-box-shadow;
    height: 80px; }

  .shop-item--media {

    & > .shop-item-media--overlay {
      display: none; }

    width: 80px;
    border-radius: $border-rounded-border-radius 0 0 $border-rounded-border-radius; }

  .shop-item-content {
    padding: $global-small-margin;
    margin: 0;
    height: 100%; }

  .shop-item--title {
    font-size: .9rem; }

  .shop-item--add-to-cart {
    height: 30px;
    width: 30px;
    line-height: 30px; }

  .shop-item {
    &:hover {
      transform: translateY(-2px);

      .shop-item--media {
        transform: none; } } }

  .shop-item-badges {
    padding: 0; }

  .shop-item-badge {
    line-height: 26px;
    width: 30px;
    height: 30px;

    &:not(:first-child) {
      margin-left: -15px; } }

  // Товар в корзине
  .shop-item {

    .cart-stepper {
      width: 100%;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center; }

    .shop-item-counter {
      padding: 5px;

      & > * {
        max-width: 100%; }

      .cart-stepper--increase {
        margin-left: 10px; }

      .cart-stepper--input {
        margin-bottom: 5px;
        order: -1;
        display: block;
        width: 100%; } } } }

.search-full-groups-items {
  overflow-y: hidden;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin-top: 10px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0; }

  & > * {
    background-color: $global-muted-background;
    border-radius: 500px;
    white-space: nowrap;
    padding: 0 10px;
    margin: 0 2px 2px 0;

    &:hover {
      background-color: $global-success-background;
      color: $global-inverse-color; }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: none; } } } }
