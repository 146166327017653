@import "uikit-variables";

/** NAVBAR */
.uk-navbar-item,
.uk-navbar-nav > li > a,
.uk-navbar-toggle {
  text-transform: uppercase;
  @media (max-width: $breakpoint-small) {
    height: 60px;
    min-height: 60px; } }

.uk-navbar-container {
  &:not(.uk-navbar-transparent) {
    box-shadow: $global-medium-box-shadow;
    border-radius: 0 0 $border-rounded-border-radius $border-rounded-border-radius;
    @media (max-width: $breakpoint-xlarge) {
      border-radius: 0; } } }

.uk-offcanvas-bar {
  box-shadow: $global-medium-box-shadow;
  max-width: $breakpoint-small; }

.uk-offcanvas-page {
  .car-container {
    filter: blur(1px);
    opacity: .2;
 } }    // transform: scale(.96)

.mm-wrapper_opened {
  .car-container {
    // transform: scale(.96)
    filter: blur(1px); } }

// Forms
// .uk-input,
// .uk-textarea
//   -webkit-user-select: text

.uk-input,
.uk-select,
.uk-textarea {
  border: $global-border-width solid $global-border;

  &:focus {
    border: $global-border-width solid rgba($global-success-background, .5); } }

.uk-button-default {
  border: $global-border-width solid $global-emphasis-color;

  &:hover {
    border-color: darken($global-emphasis-color, 5%); }

  &:active {
    border-color: darken($global-emphasis-color, 10%); } }

.uk-button-danger:disabled,
.uk-button-default:disabled,
.uk-button-primary:disabled,
.uk-button-secondary:disabled {
  border: $global-border-width solid $global-border; }

// overlay
.uk-overlay-default {
  color: $global-success-background;

  & > * {
    color: $global-success-background; } }

// .uk-leader-fill::after
.uk-leader-fill::after {
  color: $global-muted-color;
  font-size: .6rem; }

// uk-navbar-dropdown uk-dropdown
.uk-navbar-dropdown,
.uk-dropdown {
  border-radius: $border-rounded-border-radius;
  box-shadow: $global-medium-box-shadow; }

// text
.uk-text-normal {
  font-weight: 400; }

.uk-background-danger {
  background-color: $global-danger-background; }

// .uk-subnav-pill>.uk-active>a
.uk-subnav-pill > * > a {
  border-radius: 500px; }

// uk-modal
.uk-modal-footer {
  .uk-button {
    &:not(.uk-border-pill) {
      &:not(.uk-border-rounded) {
        border-radius: 500px;
        padding: 0 $global-small-gutter;
        line-height: $global-control-small-height;
        font-size: .875rem; } } } }

// uk-notification-message

.uk-notification-message {
  border-radius: $border-rounded-border-radius;

  &.uk-notification-message-success {
    background-color: $global-success-background; }

  &.uk-notification-message-danger {
    background-color: $global-danger-background; }

  &.uk-notification-message-warning {
    background-color: $global-warning-background; } }
